<template lang="pug">
#phone-container 
  div.progress(v-if='IS_SINGLE' :style="{ 'opacity':  isLoaded ? 0 : 1}") 
    div Loading... {{ parseInt(progress.toFixed(2) * 100, 10) }}%
</template>

<script>
import * as THREE from 'three';
import { onMounted } from 'vue';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls';
import { RGBELoader } from 'three/examples/jsm/loaders/RGBELoader.js';
import { loadFbx, loadGltf, loadTexture } from './core/load';
import { GUI } from 'three/examples/jsm/libs/lil-gui.module.min.js';
import { ref, computed } from 'vue';

const IS_SINGLE = true;

export default {
  name: 'Phone',
  emits: ['progress'],
  props: ['shouldPhoneRender'],
  setup(props, { emit }) {
    const progress = ref(0);

    const isLoaded = computed(() => {
      return progress.value >= 1;
    });

    onMounted(async () => {
      const scene = new THREE.Scene();
      const camera = new THREE.PerspectiveCamera(
        75,
        window.innerWidth / window.innerHeight,
        1,
        1000,
      );
      const renderer = new THREE.WebGLRenderer({ antialias: true, alpha: true });
      // // 色调映射 高动态 映射 低动态
      // renderer.toneMapping = THREE.ReinhardToneMapping;
      // // 曝光级别，越大越亮
      // renderer.toneMappingExposure = 1;
      // // gamma 输出
      // renderer.gammaOutput = true;

      camera.position.set(0, 0, 17);
      scene.add(camera);

      renderer.setSize(window.innerWidth, window.innerHeight);
      renderer.setPixelRatio(2);
      renderer.setClearColor('#f2f2f2', 0);
      const container = document.getElementById('phone-container');
      container.appendChild(renderer.domElement);

      // const light = new THREE.PointLight(0xffffff);
      // light.position.set(0, 10, 0);
      // scene.add(light);

      const hdrLoader = new RGBELoader();

      // TODO refactor
      const backboard = await loadTexture(
        'assets/models/exhibition/material/phoneMap/backboard.hdr',
        hdrLoader,
      );
      const midedge = await loadTexture(
        'assets/models/exhibition/material/phoneMap/midedge.hdr',
        hdrLoader,
      );
      const screen = await loadTexture(
        'assets/models/exhibition/material/phoneMap/screen.hdr',
        hdrLoader,
      );
      const textEnv = await loadTexture(
        'assets/models/exhibition/material/phoneMap/text.hdr',
        hdrLoader,
      );
      const normalMap = await loadTexture(
        'assets/models/exhibition/material/phoneMap/normal.png',
        new THREE.TextureLoader(),
      );
      const backboardMap = await loadTexture(
        'assets/models/exhibition/material/phoneMap/backboard-map.png',
        new THREE.TextureLoader(),
      );
      const backboardMetal = await loadTexture(
        'assets/models/exhibition/material/phoneMap/backboard-metal.png',
        new THREE.TextureLoader(),
      );

      [backboard, midedge, scene, screen, textEnv].forEach((t) => {
        t.mapping = THREE.EquirectangularReflectionMapping;
        t.minFilter = THREE.LinearMipmapLinearFilter;
        t.magFilter = THREE.NearestFilter;
      });

      const text = await loadFbx('assets/models/exhibition/model/oppo_text.fbx', {
        // onProgress: (p) => {
        //   progress.value = p;
        // },
      });

      const obj = await loadGltf('assets/models/exhibition/model/phone-0419.gltf', {
        onProgress: (p) => {
          progress.value = p;
          emit('progress', p);
        },
      });

      obj.position.set(0, -8, 0);
      text.position.set(0, -8, 0);

      // const pmremGenerator = new THREE.PMREMGenerator(renderer);
      // pmremGenerator.compileEquirectangularShader();

      let backboardMaterial;
      obj.traverse((child) => {
        if (child.isMesh) {
          const { name } = child;
          if (['antenna', 'green_key', 'mid_edge'].includes(name)) {
            child.material.envMap = midedge;
          } else if (['screen'].includes(name)) {
            child.material.envMap = screen; //pmremGenerator.fromEquirectangular(screen).texture;
          } else {
            child.material.envMap = backboard; //pmremGenerator.fromEquirectangular(backboard).texture;
          }

          if (['backboard'].includes(name)) {
            backboardMaterial = child.material;
            child.material.normalMap = normalMap;
            child.material.normalScale = new THREE.Vector2(1, 1);
            child.material.emissive = new THREE.Color(0x000000);
            child.material.reflectivity = 1;

            child.material.map = backboardMap;
            child.material.metalnessMap = backboardMetal;
          }
        }
      });

      text.traverse((child) => {
        if (child.isMesh && child.name === 'oppo_text') {
          child.material = new THREE.MeshPhysicalMaterial({
            color: new THREE.Color(0xffffff),
            emissive: new THREE.Color(0x000000),
            reflectivity: 0.5,
            roughness: 0,
            metalness: 1,
            envMap: textEnv,
          });
        }
      });

      scene.add(obj);
      scene.add(text);

      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableDamping = true;
      controls.dampingFactor = 0.05;
      controls.zoomSpeed = 0.05;
      controls.minDistance = 16;
      controls.maxDistance = 17;

      const toneMappingOptions = {
        None: THREE.NoToneMapping,
        Linear: THREE.LinearToneMapping,
        Reinhard: THREE.ReinhardToneMapping,
        Cineon: THREE.CineonToneMapping,
        ACESFilmic: THREE.ACESFilmicToneMapping,
      };

      const outPutEncodingOptions = {
        Linear: THREE.LinearEncoding,
        sRGB: THREE.sRGBEncoding,
        BasicDepthPacking: THREE.BasicDepthPacking,
        RGBADepthPacking: THREE.RGBADepthPacking,
      };

      const params = {
        exposure: 1,
        toneMapping: 'Linear',
        outputEncoding: 'sRGB',
        gamma: true,
        // reflectivity: 0.7,
        reflectivity: 1,
        // color: new THREE.Color(0x1c1c1c),
        color: new THREE.Color(0x000000),
      };

      const gui = new GUI();
      gui.add(params, 'toneMapping', Object.keys(toneMappingOptions));
      gui.add(params, 'outputEncoding', Object.keys(outPutEncodingOptions));
      gui.add(params, 'exposure', 0, 5);
      gui.add(params, 'reflectivity', 0, 1).onChange(() => {
        backboardMaterial.reflectivity = params.reflectivity;
      });
      gui.addColor(params, 'color').onChange(() => {
        backboardMaterial.emissive = params.color;
      });
      gui.open();

      const updateRenderer = () => {
        renderer.toneMapping = toneMappingOptions[params.toneMapping];
        renderer.outputEncoding = outPutEncodingOptions[params.outputEncoding];
        renderer.toneMappingExposure = params.exposure;
      };

      const render = () => {
        if (props.shouldPhoneRender || IS_SINGLE) {
          console.log('debug -> phone render');
          updateRenderer();
          controls.update();
          renderer.render(scene, camera);
        }
        window.requestAnimationFrame(render);
      };

      render();
    });

    return { progress, isLoaded, IS_SINGLE };
  },
};
</script>

<style lang="stylus">
#phone-container
  position absolute
  top 0
  left 0
  width 100vw
  height 100vh
  .progress
    color #black
    font-size 100px
    position absolute
    z-index 9
    top 50%
    left 50%
    transform translateX(-50%) translateY(-50%)
    pointer-events none
</style>
